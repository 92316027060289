import Swipe from 'swipejs'

var animationLength = 500; // Change this in _grensesnitt-lightbox.scss as well if changed

var $grensesnittLightboxContainer = $('.gl-container');
var $lightboxes = $('.lightbox');
var $navPrev = $('.gl-nav-prev > a');
var $navNext = $('.gl-nav-next > a');
var swipe;
var noMobileScroll;

$lightboxes.each(function (index, el) {
    var $el = $(el);

    $el.attr('data-index', index);
});

$lightboxes.click(function (e) {
    e.preventDefault();

    showLightbox();

    if (swipe) {
        swipe.kill();
        swipe = null;
    }

    addImages();

    swipe = new Swipe($('.gl-swipe')[0], {
        draggable: true,
        continuous: true,
        disableScroll: true
    });

    $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
        e.preventDefault();
    });

    var index = parseInt($(this).data('index')) || 0;
    swipe.slide(index, 1);
});

$('.gl-container .gl-close').click(function (e) {
    e.preventDefault();

    hideLightbox();
});

$navNext.click(function (e) {
    e.preventDefault();

    swipe.next();
});

$navPrev.click(function (e) {
    e.preventDefault();

    swipe.prev();
});

$('.gl-image').click(function (e) {
    if (e.target.tagName === 'IMG') {
        return;
    }

    hideLightbox();
});

function addImages() {
    var $swipeWrap = $('.gl-swipe-wrap');

    $swipeWrap.html('');

    $lightboxes.each(function (index, el) {
        var url = el.href;

        $swipeWrap.append('<div><img src="' + url + '" /></div>')
    })
}

function hideLightbox() {
    $grensesnittLightboxContainer.removeClass('fade');

    setTimeout(function () {
        $grensesnittLightboxContainer.removeClass('show');
        $('body').removeClass('noscroll');
    }, animationLength);
}

function showLightbox() {
    $grensesnittLightboxContainer.addClass('show');

    setTimeout(function () {
        $grensesnittLightboxContainer.addClass('fade');

        setTimeout(function () {
            $('body').addClass('noscroll');
        }, animationLength);
    }, 10);
}