var $filterItems = $('.project-filter li');
var $projects = $('.projects .project');

$filterItems.find('a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var category = $this.data('type');

    $filterItems.removeClass('active');
    $this.parent().addClass('active');

    $projects.attr('data-index', '').attr('data-modulo-index', '');

    if (category) {
        $projects.hide();
        var $filteredProjects = $('.projects .project.' + category);
        $filteredProjects.show();
        $filteredProjects.each(function(index, el) {
            var $el = $(el);
            $el.attr('data-index', '' + index);
            $el.attr('data-modulo-index', '' + index % 2);
        });
    } else {
        $projects.show();
        $projects.each(function(index, el) {
            var $el = $(el);
            $el.attr('data-index', index);
            $el.attr('data-modulo-index', index % 2);
        })
    }
});