
$('nav li.menu a').click(function(e) {
    e.preventDefault();

    openMenu();
});

$('#close-menu').click(function(e) {
    e.preventDefault();

    closeMenu();
});

function openMenu() {
    $('body').addClass('no-scroll');

    $('#mobile-nav').addClass('show');
    setTimeout(function() {
        $('#mobile-nav').addClass('fade');
    }, 50);
}

function closeMenu() {
    $('body').removeClass('no-scroll');

    $('#mobile-nav').removeClass('fade');
    setTimeout(function() {
        $('#mobile-nav').removeClass('show');
    }, 300);
}