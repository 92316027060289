
var currentPeopleLocation = '';
var currentPeopleDiscipline = '';

$('.people .people-filter a.title').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var isActive = $this.parent().hasClass('active');

    $('.people .people-filter > div').removeClass('active');

    if (!isActive) {
        $this.parent().addClass('active');
    }
});

$('.people .people-filter .location select').change(function(e) {
    e.preventDefault();

    var $this = $(this);
    var discipline = $this.val();
    var text = $this.find('option:selected').text();
    currentPeopleLocation = discipline;

    $this.parents('.location').find('span').text(text);
    filterPeople(currentPeopleLocation, currentPeopleDiscipline);
});

$('.people .people-filter .discipline select').change(function(e) {
    e.preventDefault();

    var $this = $(this);
    var discipline = $this.val();
    var text = $this.find('option:selected').text();
    currentPeopleDiscipline = discipline;

    $this.parents('.discipline').find('span').text(text);
    filterPeople(currentPeopleLocation, currentPeopleDiscipline);
});

function filterPeople(location, discipline) {
    var $person = $('.person');
    $person.addClass('hide');

    if (location && discipline) {
        $('.person.' + location + '.' + discipline).removeClass('hide');
    } else if (location) {
        $('.person.' + location).removeClass('hide');
    } else if (discipline) {
        $('.person.' + discipline).removeClass('hide');
    } else {
        $person.removeClass('hide');
    }

    var $people = $('.person:not(.hide)');
    $people.removeClass('sm-last').removeClass('md-last').removeClass('lg-last');

    $people.each(function(index, person) {
        var $person = $(person);

        if (index % 2 === 1) {
            $person.addClass('sm-last');
        }

        if (index % 3 === 2) {
            $person.addClass('md-last');
        }

        if (index % 4 === 3) {
            $person.addClass('lg-last');
        }
    })

}