import bodymovin from 'lottie-web'

var animations = document.querySelectorAll('.animation');

if ('IntersectionObserver' in window) {

    var options = {
        root: null, // relative to document viewport
        rootMargin: '0px 0px 200px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0 // visible amount of item shown in relation to root
    };

    var onIntersectionChange = function (changes, observer) {
        changes.forEach(change => {
            if (change.intersectionRatio > 0) {
                loadAnimation(change.target);
            }
        });
    };

    var observer = new IntersectionObserver(onIntersectionChange, options);

    function setup() {
        animations.forEach(element => observer.observe(element));
    }

    setTimeout(setup, 200);
    setTimeout(setup, 1500);
    setTimeout(setup, 3000);
} else {
    animations.forEach(element => loadAnimation(element));
}

function loadAnimation(element) {
    if (element.classList.contains('loaded')) {
        return;
    }

    var index = element.getAttribute('data-animation');
    index = index.substr(index.length - 1, 1);

    var animation = bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/0' + index + '/data.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: true, // Optional
        name: "", // Name for future reference. Optional.
    });

    animation.addEventListener('DOMLoaded', function () {
        setTimeout(function() {
            element.classList.add('show');
        }, 500);
    });

    element.classList.add('loaded');
}



