document.addEventListener("DOMContentLoaded", function() {
    const galleries = document.querySelectorAll('section.image-gallery');

    if(galleries.length > 0) {
        galleries.forEach(function(gallery, index) {
            let hoverLeft = gallery.querySelector('.left-side');
            let prevBtn = gallery.querySelector('button.slick-prev');
            hoverLeft.addEventListener('mouseover', function() {
                prevBtn.classList.add('active');
            });
            hoverLeft.addEventListener('mouseout', function() {
                prevBtn.classList.remove('active');
            });

            hoverLeft.addEventListener('click', function() {
                prevBtn.click();
            });

            let hoverRight = gallery.querySelector('.right-side');
            let nextBtn = gallery.querySelector('button.slick-next');
            hoverRight.addEventListener('mouseover', function() {
                nextBtn.classList.add('active');
            });
            hoverRight.addEventListener('mouseout', function() {
                nextBtn.classList.remove('active');
            });
            hoverRight.addEventListener('click', function() {
                nextBtn.click();
            });

            if(window.innerWidth > 900) {
                window.addEventListener("mousemove", e => {
                    let fieldPosition = e.target.getBoundingClientRect();
                    let galleryWidth = gallery.querySelector('.slick-slideshow').offsetWidth;
                    clientX = e.clientX - fieldPosition.left;
                    clientY = e.clientY - fieldPosition.top;
                    if(prevBtn.classList.contains('active')) {
                        prevBtn.style.left = clientX + 'px';
                        prevBtn.style.top = clientY + 'px';
                    }
                    if(nextBtn.classList.contains('active')) {
                        nextBtn.style.right = 'unset';
                        nextBtn.style.left = (galleryWidth/2) + clientX + 'px';
                        nextBtn.style.top = clientY + 'px';
                    }
                });
            }
        });
    }
});