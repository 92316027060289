import { intersectionObserve } from "./_intersection-observer";

var playOnceVideos = document.querySelectorAll('.large-image video.play-once');
intersectionObserve(playOnceVideos, 0, (target, intersectionRatio) => {
    if (intersectionRatio > 0 && target.paused) {
        target.play();
    }
});

let blocks = document.querySelectorAll('.large-image');
if(blocks.length > 0) {
    blocks.forEach(function(block, index) {
        let playBtn = block.querySelector('button');
        if(playBtn) {
            playBtn.addEventListener('click', function(){
                let video = playBtn.parentElement.querySelector('video');
                playBtn.classList.add('hide');
                playBtn.parentElement.querySelector('.overlay').classList.add('hide');
                video.play();
                video.setAttribute('controls', true);
            });
        }
    });
}