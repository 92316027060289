import 'slick-carousel/slick/slick';

function initSlider(id) {
    $('#' + id).slick({
        lazyLoad: 'ondemand',
        infinite: true,
        useTransform: false,
        dots: false,
        arrows: true,
        swipe: false,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1000,
            settings: {
                //arrows: true,
                swipe: true,
            }
        }],
        nextArrow: '<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.13 40.55"><path fill-rule="evenodd" clip-rule="evenodd" fill="#231f20" d="M9.05 22.37h26.46l-5.06 10.32h1.73l9.34-12-9.34-12h-1.73l5.06 10.32H9.05z"/></svg></button>',
        prevArrow: '<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.13 40.55"><path fill-rule="evenodd" clip-rule="evenodd" fill="#231f20" d="M9.05 22.37h26.46l-5.06 10.32h1.73l9.34-12-9.34-12h-1.73l5.06 10.32H9.05z"/></svg></button>',
    });
    $('#' + id).on('afterChange', function(event, slick, currentSlide, nextSlide){
        if($('.slide-index')) {
            $('.slide-index').find('span').html(currentSlide + 1);
        }
    });
};
window.initSlider = initSlider;